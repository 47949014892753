import React, { useEffect, useState, useMemo } from 'react';
import {
    // Button,
    Card,
    CardBody,
    CardHeader,
    Row,
    Col,
    Button,
    // Col,
    // CustomInput,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    // InputGroup,
    // Row,
    Table,
    UncontrolledDropdown
} from 'reactstrap';
// import { useTable } from 'react-table';
import { pageVisits, pageTraffic, pageRanking } from "../../data/tables";
import BootstrapTable from 'react-bootstrap-table-next';
import { authHeader, handleResponse,  history } from '../../_helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faPlus, faAngleUp, faArrowDown, faArrowUp, faEdit, faEllipsisH, faExternalLinkAlt, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';


const Users = () => {


    const [ users, setUsers ] = useState([])
    const [ ready, setReady ] = useState(false)

    useEffect(() => {
        getUsers()
    }, [])

    const getUsers = async () => {

        const requestOptions = { method: 'GET', headers: await authHeader() };

        console.log('requestOptions', requestOptions)
        
        const response = await fetch(`${process.env.apiUrl}/v1/users`, requestOptions)
        let json = await response.json()
        
        if(json.success) {
            setUsers([...json.results])
            setReady(true)
        } 

    }

    const editRow = (id) => {
        history.push(`/admin/users/edit/${id}`)
    }

    const newUser = (id) => {
        history.push(`/admin/users/edit/new`)
    }

    const TableRow = (props) => {

        const { id, name, email, role } = props;

        return (
            <tr>
                {/* <th scope="row">{id}</th> */}
                <td>
                    <Button size="sm" onClick={()=>editRow(id)}><FontAwesomeIcon icon={faEdit} /> Modifier</Button>
                </td>                  
                <td>{name}</td>     
                <td>{email}</td>          
                <td>{role}</td>          
            </tr>
        );
    };

    
    if(!ready) {
        return <div>Loading</div>
    }    

    return (
        <Card border="light" className="shadow-sm">
          <CardHeader>
            <Row className="align-items-center">
              <Col>
                <h2>Utilisateurs</h2>
              </Col>
              <Col className="text-end">
                <Button size="sm"  onClick={newUser}><FontAwesomeIcon icon={faPlus}/> Ajouter un utilisateur</Button>
              </Col>
            </Row>
          </CardHeader>
          <Table responsive className="align-items-center table-flush">
            <thead className="thead-light">
              <tr>
                {/* <th scope="col">ID</th> */}
                <th scope="col">Actions</th>
                <th scope="col">Nom</th>
                <th scope="col">Courriel</th>
                <th scope="col">Rôle</th>
                
                {/* <th scope="col">Page Value</th>
                <th scope="col">Bounce rate</th> */}
              </tr>
            </thead>
            <tbody>
              {users.map(user => <TableRow key={`user-${user._id}`} {...user} />)}
            </tbody>
          </Table>
          
        </Card>
      );

    return (
        <div>Users</div>
    )
}

export default Users