import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

// pages
import Presentation from "../_pages/Presentation";
import Upgrade from "../_pages/Upgrade";
import DashboardOverview from "./dashboard/DashboardOverview";

import Profil from './profil/Profil';
import FAQ from './faq/FAQ';


import Transactions from "../_pages/Transactions";
// import Settings from "../_pages/Settings";
import BootstrapTables from "../_pages/tables/BootstrapTables";
import Signin from "./examples/Signin";
// import Signup from "./examples/Signup";
import ForgotPassword from "./examples/ForgotPassword";
import ResetPassword from "./examples/ResetPassword";
import Lock from "./examples/Lock";
import NotFoundPage from "./examples/NotFound";
import ServerError from "./examples/ServerError";

import AdminOverview from './Admin/Overview'
import AdminUsers from './Admin/Users'
import AdminUserEdit from './Admin/UserEdit'


import Channels from './Channels';
import Traps from './Traps';
import Tasks from './Tasks';
import Overview from './Overview';
import Parametres from "./Parametres";

import { AppContext } from '../_context'

import useWebSocket, { ReadyState } from 'react-use-websocket';

import { Responsive as ResponsiveGridLayout } from 'react-grid-layout';



import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

// import Accordion from "../_pages/components/Accordion";
// import Alerts from "../_pages/components/Alerts";
// import Badges from "../_pages/components/Badges";
// import Breadcrumbs from "../_pages/components/Breadcrumbs";
// import Buttons from "../_pages/components/Buttons"; 
// import Forms from "../_pages/components/Forms";
// import Modals from "../_pages/components/Modals";
// import Navs from "../_pages/components/Navs";
// import Navbars from "../_pages/components/Navbars";
// import Pagination from "../_pages/components/Pagination";
// import Popovers from "../_pages/components/Popovers";
// import Progress from "../_pages/components/Progress";
// import Tables from "../_pages/components/Tables";
// import Tabs from "../_pages/components/Tabs";
// import Tooltips from "../_pages/components/Tooltips";
// import Toasts from "../_pages/components/Toasts";

//import PrivateRoute from '../components/PrivateRoute';
import { isLogin } from '../utils';

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => ( <> <Preloader show={loaded ? false : true} /> <Component {...props} /> </> ) } />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }
  /*
  return (
    <PrivateRoute { ...rest} component={Component} render={props => {
      console.log('Private', props)
      return (
      <>
        <Preloader show={loaded ? false : true} />
        <Sidebar />

        <main className="content">
          <Navbar />
          <Component {...props} />
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </>
      )
      }
    } />
    // path="/" exact component={DashboardOverview}  allowedRoles={['admin', 'commerce', 'client']}/>    
  )
        <Route {...rest} render={props => (
            isLogin() ?
                <Component {...props} />
            : <Redirect to="/login" />
        )} />  
  */
 
  return (
    <Route {...rest} render={props => (

      isLogin() ? 
      <>
        <Preloader show={loaded ? false : true} />
        <Sidebar />

        <main className="content">
          <Navbar />
          

          <Component {...props} /> 
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </> : <Redirect to="/login" /> 
    )}
    />
  );
};

export default () => (
  <>
    
    <Switch>
      {/* <RouteWithLoader exact path={Routes.Presentation.path} component={Presentation} />
      <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
      <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
      
      <RouteWithLoader exact path={Routes.ResetPassword.path} component={ResetPassword} />
      <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
      <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
      <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} /> */}
      <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
      <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
      <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
      <RouteWithLoader exact path={Routes.ResetPassword.path} component={ResetPassword} />
      {/* <RouteWithSidebar exact path={Routes.Layout.path} component={Layout} /> */}
      {/* <RouteWithSidebar exact path={Routes.Signin.path} component={Preloader}/> */}
      
      {/* pages */}
      <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} />
      <RouteWithSidebar exact path={Routes.Overview.path} component={Overview} />
      

      <RouteWithSidebar exact path={Routes.Channels.path} component={Channels} />
      <RouteWithSidebar exact path={Routes.Traps.path} component={Traps} />
      <RouteWithSidebar exact path={Routes.Tasks.path} component={Tasks} />

      <RouteWithSidebar exact path={Routes.AdminOverview.path} component={AdminOverview} />
      <RouteWithSidebar exact path={Routes.AdminUsers.path} component={AdminUsers} />
      <RouteWithSidebar exact path={Routes.AdminUserEdit.path} component={AdminUserEdit} />

      <RouteWithSidebar exact path={Routes.Profil.path} component={Profil} />
      <RouteWithSidebar exact path={Routes.FAQ.path} component={FAQ} />

      <RouteWithSidebar exact path={Routes.Parametres.path} component={Parametres} />
      
      {/* <Route exact path="/fullcanvas" component={FullCanvas} />
      <Route exact path="/pdftron" component={PDFTron} /> */}
      {/* <RouteWithSidebar exact path={Routes.Upgrade.path} component={Upgrade} />
      <RouteWithSidebar exact path={Routes.Transactions.path} component={Transactions} />
      <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} />
      <RouteWithSidebar exact path={Routes.BootstrapTables.path} component={BootstrapTables} /> */}
      
      {/* components */}
      {/* <RouteWithSidebar exact path={Routes.Accordions.path} component={Accordion} />
      <RouteWithSidebar exact path={Routes.Alerts.path} component={Alerts} />
      <RouteWithSidebar exact path={Routes.Badges.path} component={Badges} />
      <RouteWithSidebar exact path={Routes.Breadcrumbs.path} component={Breadcrumbs} />
      <RouteWithSidebar exact path={Routes.Buttons.path} component={Buttons} />
      <RouteWithSidebar exact path={Routes.Forms.path} component={Forms} />
      <RouteWithSidebar exact path={Routes.Modals.path} component={Modals} />
      <RouteWithSidebar exact path={Routes.Navs.path} component={Navs} />
      <RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars} />
      <RouteWithSidebar exact path={Routes.Pagination.path} component={Pagination} />
      <RouteWithSidebar exact path={Routes.Popovers.path} component={Popovers} />
      <RouteWithSidebar exact path={Routes.Progress.path} component={Progress} />
      <RouteWithSidebar exact path={Routes.Tables.path} component={Tables} />
      <RouteWithSidebar exact path={Routes.Tabs.path} component={Tabs} />
      <RouteWithSidebar exact path={Routes.Tooltips.path} component={Tooltips} />
      <RouteWithSidebar exact path={Routes.Toasts.path} component={Toasts} /> */}

      {/* documentation */}
      {/* <RouteWithSidebar exact path={Routes.DocsOverview.path} component={DocsOverview} />
      <RouteWithSidebar exact path={Routes.DocsDownload.path} component={DocsDownload} />
      <RouteWithSidebar exact path={Routes.DocsQuickStart.path} component={DocsQuickStart} />
      <RouteWithSidebar exact path={Routes.DocsLicense.path} component={DocsLicense} />
      <RouteWithSidebar exact path={Routes.DocsFolderStructure.path} component={DocsFolderStructure} />
      <RouteWithSidebar exact path={Routes.DocsBuild.path} component={DocsBuild} />
      <RouteWithSidebar exact path={Routes.DocsChangelog.path} component={DocsChangelog} /> */}    

      <Redirect to={Routes.NotFound.path} />
    </Switch>
  </>
);
