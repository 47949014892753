
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import Password from '../Admin/Password';
import { Routes } from "../../routes";

//import BgImage from "../../assets/img/pages/sign-in-background.jpg";

const ResetPassword = () => {

  const [ password, setPassword ] = useState('')

  return (
    <main>
      <section className="vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center"  style={{ height:'100vh', backgroundImage: `url(/img/5838290.jpg)` } }>
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3 className="mb-4">Réinitialiser le mot de passe</h3>
                <Form>
                  {/* <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="email" placeholder="example@company.com" />
                    </InputGroup>
                  </Form.Group> */}
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Nouveau mot de passe</Form.Label>
                    
                    <Password password={''} clickable={false} onChange={(password)=>setPassword(password)}/>

                    {/* <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control required type="password" placeholder="Password" />
                    </InputGroup> */}
                  </Form.Group>


                  {/* <Form.Group id="confirmPassword" className="mb-4">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control required type="password" placeholder="Confirm Password" />
                    </InputGroup>
                  </Form.Group> */}
                  <Button variant="primary" type="submit" className="w-100">
                    Changer votre mot de passe
                  </Button>
                </Form>
                <br/>
                <p className="text-center">
                  <Card.Link as={Link} to={Routes.Signin.path} className="text-gray-700">
                    <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Retour à la connexion
                  </Card.Link>
                </p>                   
              </div>           
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default ResetPassword;