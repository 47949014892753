import React, { useState, useEffect } from 'react';
import '../../scss/PasswordStrengthMeter.scss';
import zxcvbn from 'zxcvbn';
import { Button, Row, Col, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faEye, faEyeSlash, faLock } from '@fortawesome/free-solid-svg-icons'

const randomString = (length, chars) => {
    var mask = '';
    if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
    if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    if (chars.indexOf('#') > -1) mask += '0123456789';
    if (chars.indexOf('!') > -1) mask += '-!@#$^_:,.';
    var result = '';
    for (var i = length; i > 0; --i) result += mask[Math.floor(Math.random() * mask.length)];
    return result;
}


const PasswordStrengthMeter = ({ onChange, clickable }) => {

    const [ passwordOpen, setPasswordOpen ] = useState(clickable ? false : true )
    const [ passwordShow, setPasswordShow ] = useState(false)
    const [ password, setPassword ] = useState('')

    const createPasswordLabel = (result) => {
        switch (result.score) {
            case 0:
            return 'Faible';
            case 1:
            return 'Faible';
            case 2:
            return 'Minimale';
            case 3:
            return 'Medium';
            case 4:
            return 'Forte';
            default:
            return 'Faible';
        }
    }

    const passwordSuggestion = (password) => {
        

        var rules = [
        {
            result: (password.match(/[A-Za-z]/) != null),
            message: <span>Au moins <strong>une lettre</strong></span>,
        },
        {
            result: (password.match(/[A-Z]/) != null),
            message: <span>Au moins <strong>une lettre majuscule</strong></span>,
        },
        {

            result: (password.match(/[0-9]/) != null),
            message: <span>Au moins <strong>un chiffre</strong></span>,
        },
        {
            result: (password.match(/[-!@#$^_:,.]/) != null),
            message: <span>Au moins <strong>un caractère spécial</strong> (-!@#$^_:,.)</span>,
        },
        {
            result: (password.length > 8),
            message: <span>Minimum de 8 caractères</span>,
        }
        ]


        console.log('passwordSuggestion',passwordOpen, {password}, rules, )


        return (
        <div>
        {
            rules.map((r,i) => {
            console.log('r',r)
            if(r.result) {
                return (
                <div key={`r_${i}`} className={'text-success'}><FontAwesomeIcon icon={faCheck}/> {r.message}</div> 
                )
            } else {
                return (
                <div key={`r_${i}`} className={'text-danger'}><FontAwesomeIcon icon={faTimes}/> {r.message}</div> 
                )              
            }

            })
        }
        </div>
        )
    }

    const cancelChangePassword = () => {

        setPasswordOpen(false)
        setPasswordShow(false)
        setPassword('')
    }

    const generatePassword = () => {
        let password = randomString(15, 'aA#!')
        setPassword(password)
        setPasswordShow(true)
    }
   
    useEffect(() => {
        console.log('password change')
        onChange(password);        
    }, [password]);

    const testedResult = zxcvbn(password);
    const suggestion = passwordSuggestion(password)

    console.log('suggestion', suggestion, password)


    if(!passwordOpen) {
        return (
            <Row>
                <Col><Button color={'warning'} onClick={()=>setPasswordOpen(true)}><FontAwesomeIcon icon={faLock}/> Modifier le mot de passe</Button></Col>
            </Row>
        )
    }


    return (
        <>

            <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                    <InputGroupText id="show_password" onClick={()=>setPasswordShow(!passwordShow)}>
                        <FontAwesomeIcon icon={passwordShow?faEyeSlash:faEye} />
                    </InputGroupText>
                </InputGroupAddon>
               
                <Input
                    placeholder="Username"
                    aria-label="Username"
                    aria-describedby="show_password"
                    //placeholder={t("Entrez un nouveau de passe")}
                    placeholder={'Entrez un nouveau de passe'}
                    value={password}	
                    type={passwordShow?'text':'password'}								      
                    onChange={e => setPassword(e.target.value)}									      
                    className={'gen_password_input'}
                />										
                <InputGroupAddon addonType="prepend">
                    <InputGroupText onClick={generatePassword} id="gen_password">Générer</InputGroupText>
                </InputGroupAddon>

            </InputGroup>
            { clickable &&
                <Button onClick={cancelChangePassword} size={'sm'} color={'danger'}><FontAwesomeIcon icon={faTimes}/> annuler le changement de mot de passe</Button>
            }       

            <div className="password-strength-meter">
            <progress
                className={`password-strength-meter-progress strength-${createPasswordLabel(testedResult)}`}
                value={testedResult.score}
                max="4"
            />
            <br />
            <label
                className="password-strength-meter-label"
            >
                {password && (
                <div>
                    <strong>Difficulté du mot de passe:</strong> {createPasswordLabel(testedResult)}
                </div>
                )}
            </label>
            {suggestion}
            </div>
            
        </>
    );
  
}

export default PasswordStrengthMeter;