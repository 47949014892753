import React, { useState, useEffect } from 'react';
import GridLayout from 'react-grid-layout';
import useSize from '@react-hook/size'
import { Paper } from '@mui/material';

import { authHeader } from '../../_helpers';

import ListTraps from './ListTraps'
import Trap from './Trap'



const Layout = () => {

    const target = React.useRef(null)
    const [width, height] = useSize(target)
    const [ openBoxes, setOpenBoxes ] = useState([])

    const [ ready, setReady ] = useState(false);
    const [ traps, setTraps ] = useState([])
    const [ channels, setChannels ] = useState([]);
    const [ layout, setLayout ] = useState([ {i: 'a', x: 0, y: 0, w: 4, h: 20, static: true}, ]);

    const openBox = (trap) => {
        console.log('openBox', trap)
        openBoxes.push(trap)
        setOpenBoxes([...openBoxes])
    }



    useEffect(()=> { 
        // getChats();
        // getChannels();
        // getTraps();
        getOverview();
    
        // traps.map(trap => {
        //     layout.push({i: trap.id, x: 5, y: 0, w: 2, h: 10, static: false})            
        // })
        setLayout([...layout])

    }, []);

    const getOverview = async () => {

        fetch(`${process.env.apiUrl}/v1/overview`, {
            headers: await authHeader(),
            method: 'GET'
        })
        .then(function(response) {
            return response.json();
        })
        .then(function(json) {       
            
            
            setTraps([...json.traps])
            setChannels([...json.channels])
            setReady(true)

        });
    }

    const getChannels = () => {

        fetch(`${process.env.apiUrl}/v1/chat-updates/channels`)
        .then(function(response) {
            return response.json();
        })
        .then(function(json) {       
            
            
            setChannels([...json.results])
            
            setReady(true)

        });
    }

    const onLayoutChange = (layout) => {
        console.log('onLayoutChange', layout)
        //setLayout([...layout])
    }   

    if(!ready) return <div>Loading!</div>

    
    // traps.map(trap => {
    //     layout.push({i: trap.id, x: 5, y: 0, w: 2, h: 10, static: false})
    // })
    
    console.log(`Layout avant render`, layout)

    return (
        <>
        
            <div className="trap-list">
                { traps.map(trap => {
                        return (
                            <Trap trap={trap} getTraps={getOverview}/>
                        )
                    })}
            </div>
        </>
    )
    return ( 
        <div className="full" ref={target}>
            <GridLayout onLayoutChange={onLayoutChange} className="layout" layout={layout} cols={12} rowHeight={30} width={width}>
                <div key="a">
                    <ListTraps 
                        openBox={openBox}
                        traps={traps}
                        channels={channels}
                    />
                </div>

                {/* { openBoxes.map(trap => {
                    return (
                        <div key={trap.id}>
                            <Paper className="trap_block">
                                { trap.triggers[0].text }
                                { }
                            </Paper>                                                        
                        </div>
                    )
                })} */}

                { traps.map(trap => {
                    return (
                        <div key={trap.id}>
                            <Paper className="trap_block">
                                { trap.triggers[0].text }
                                { trap.messages.map(msg => {
                                    return (
                                        <div className="msg">
                                            { msg.message }
                                            { msg.createdAt }
                                            { msg.tasks.map(task => {
                                                return (
                                                    <div>
                                                        { JSON.stringify(task) }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                                                              
                            </Paper>                                                        
                        </div>
                    )
                })}
            </GridLayout>
        </div>

    )
}

export default Layout