import React, { useState, useEffect, useRef, useContext } from 'react';
import { Row, Col, Input, Button } from 'reactstrap';
import { useTextSelection } from 'use-text-selection';
import { ChannelContext } from '../../_context';
// import { Select2 } from "select2-react-component";
// import Select2 from 'react-select2-wrapper';
import { authHeader } from '../../_helpers';

import Action from './Action';
import NewAction from './NewAction';
// import Select from 'react-select'

const ContextMenu = ({ ...rest }) => {
    return (
        <div {...rest}>
            Selection
        </div>
    )
}
const Channels = () => {

    const refEl = useRef(null)

    const { clientRect, isCollapsed } = useTextSelection(refEl.current)
    const [ currentSelection, setCurrentSelection ] = useState('')

    const [ currentAction, setCurrentAction ] = useState(0);
    const [ chats, setChats ] = useState([]);
    const [ channels, setChannels ] = useState([]);    
    const [ currentChannel, setCurrentChannel ] = useState( localStorage.getItem('currentChannel') !== '' ? JSON.parse(localStorage.getItem('currentChannel')) : '' );    
    
    const [ actions, setActions ] = useState(   [] );
    
    const [ action, setAction ] = useState({ triggers:[], executes: [], preview: false })

    // const [ triggers, setTriggers ] = useState(  JSON.parse(localStorage.getItem('triggers')) ?? [] );
    // const [ actions, setActions ] = useState(  JSON.parse(localStorage.getItem('actions')) ?? [] );
    const [ ready, setReady ] = useState(false);   
    
    let selectedText = window.getSelection().toString();
 

    useEffect(()=> { 
        // getChats();
        getChannels();
        getActions();
    }, []);
    

    useEffect(()=> {
        if(currentChannel !== '') getChats();
    }, [currentChannel]);


    // useEffect(() => {
        
    //     saveActions();
    //     //localStorage.setItem('actions', JSON.stringify(actions));

    // }, [actions, action]);  

    const getChats = () => {

        fetch(`${process.env.apiUrl}/v1/chat-updates/?channel_id=${currentChannel}`)
        .then(function(response) {
            return response.json();
        })
        .then(function(json) {       
            
            setChats([...json.results])
            
            // setReady(true)

        });
    }

    const changeChannel = (channelId) => {
        if(channelId === '')
        localStorage.setItem('currentChannel', channelId)
        setCurrentChannel(channelId)

    }


    const getTraps = () => {

        fetch(`${process.env.apiUrl}/v1/traps`)
        .then(function(response) {
            return response.json();
        })
        .then(function(json) {       
            
            
            setActions([...json.results])
            
            setReady(true)

        });
    }

    const getChannels = () => {

        fetch(`${process.env.apiUrl}/v1/chat-updates/channels?enabled=1`)
        .then(function(response) {
            return response.json();
        })
        .then(function(json) {       
            
            
            setChannels([...json.results])
            
            setReady(true)

        });
    }

    const getActions = async() => {
    //    const actions = JSON.parse(localStorage.getItem('actions')) ?? []
       // setActions( actions )    
       fetch(`${process.env.apiUrl}/v1/traps/`, 
       {
           method: 'GET',
           headers: await authHeader()
       })
       .then(res => res.json())
       .then(res => {
            setActions([ ...res.results ])    
       });     

    }

    const addAction = () => {
        
        setActions([...actions, { triggers: [], executes: [] }])
        // setActions([...actions, {
        //     triggers: [ window.getSelection().toString() ]
        // }])
    }   

    useEffect(()=> {
        console.log('selection Change')
        let newText = window.getSelection().toString()
        if(newText !== '')  setCurrentSelection( newText )

    }, [selectedText]);

    const saveAction = (newAction) => {
        
        actions.push(newAction)
        setActions([...actions])        
        saveActions()
    }

    const saveActions = async () => {


        fetch(`${process.env.apiUrl}/v1/traps/save`, 
        {
            method: 'POST',
            headers: await authHeader(),
            body: JSON.stringify({ actions })
        })
        .then(res => res.json())
        .then(res => {
            
            setActions([ ...res.results ])    
        });     

    }

    const deleteAction = async (action) => {

        

        
        fetch(`${process.env.apiUrl}/v1/traps/`, 
        {
            method: 'DELETE',
            headers: await authHeader(),
            body: JSON.stringify({ action })
        })
        .then(res => res.json())
        .then(res => {

            if(res.success) {
                actions.splice(actions.indexOf(action), 1)
                setActions([...actions])
            }
        });     

        
    }

    const checkChat = (chat) => {
        
        let actionsToCheck = [...actions, action];
        let show = true

        actionsToCheck.map(action => {

            if(!action.preview) return

            action.triggers.map(trigger => {
                if(chat.message.indexOf(trigger.text) < 0) {
                    show = false
                }

            })
            // console.log(action)
            // debugger

        })
        //if(chat.message.indexOf()
        //console.log(chat, actions, action)
        //return true
        return show

    }

    const channelContextValues = {
        actions,
        setActions,
    }

    if(!ready) return <div>Loading</div>

    // console.log({ clientRect, refEl })
    // let channelsList = Object.keys(channels).map(cId => {
        
    //     let channel = channels[cId]

    //     return {
    //         value:cId,
    //         label:channel.title
    //     }
    // })
  
    return (

        <div>
            <ChannelContext.Provider value={channelContextValues}>

                <Row>
                        <Col md={12}>

                            <Input type="select" value={currentChannel} onChange={({target})=>changeChannel(target.value)}>
                                <option value=''>Choisir un channel</option>
                                { channels.map(channel => {
                                    //let channel = channels[cId]
                                    //return <option>{ cNum }</option>
                                    return <option key={channel.id} value={channel.id}>{channel.title}</option>
                                })}                        
                            </Input>

                        </Col>
                </Row>

                <Row className={'channels'}>
                        <Col md={7}>
                            <div className={'chat-scroller'} ref={refEl}>
                                { chats.map(chat => {

                                    if(!checkChat(chat)) return <></>

                                    return (
                                        <div key={chat.message_id} className={'chat_wrapper'}>
                                            <div className={'chat_avatar'}>
                                            </div>
                                            <div className={'chat_message'}>
                                                <div className={'chat_date'}>
                                                    { new Date(chat.date*1000).toISOString() }    
                                                </div>
                                                <div className={'chat_content'}>
                                                    { chat.message }    
                                                </div>                            
                                            </div>

                                        </div>
                                    )
                                })}
                            </div> 
                        </Col>
                        {/* <ContextMenu style={{ left: clientRect.x, top: clientRect.y }}/> */}
                        
                    
                        <Col md={5}>
                                
                            <div className={'actions'}>

                                <NewAction currentChannel={currentChannel} action={action} setAction={setAction} saveAction={saveAction} currentSelection={currentSelection} setCurrentSelection={setCurrentSelection}/>

                                <h4 className={'h4'}>Autres trappes</h4>
                                { actions.map(action => {

                                    return (
                                        <Action deleteAction={deleteAction} setAction={setAction} action={action} />
                                    )

                                })}

                            </div>

                            {/* <Button onClick={saveActions}>saveActions</Button> */}

                        </Col>
                </Row>

            </ChannelContext.Provider>
            
        </div>

    )
    return (
        <>
            <ChannelContext.Provider value={channelContextValues}>
                <Row>
                    <Col md={12}>

                        {/* <Select options={channelsList}
                             value={ currentChannel }
                            //  onChange={value => changeChannel(value)}
                             /> */}
                        

                        {/* <Input type="select" value={currentChannel} onChange={({target})=>changeChannel(target.value)}>
                            { channels.map(channel => {
                                //let channel = channels[cId]
                                //return <option>{ cNum }</option>
                                return <option key={channel.id} value={channel.id}>{channel.title}</option>
                            })}                        
                        </Input> */}
                    </Col>
                </Row>
                <Row className={'channels'}>
                    <Col md={7}>
                        {/* <div className={'chat-scroller'} ref={refEl}>
                            { chats.map(chat => {

                                if(!checkChat(chat)) return <></>

                                return (
                                    <div key={chat.message_id} className={'chat_wrapper'}>
                                        <div className={'chat_avatar'}>
                                        </div>
                                        <div className={'chat_message'}>
                                            <div className={'chat_date'}>
                                                { new Date(chat.date*1000).toISOString() }    
                                            </div>
                                            <div className={'chat_content'}>
                                                { chat.message }    
                                            </div>                            
                                        </div>

                                    </div>
                                )
                            })}
                        </div> */}
                    </Col>
                    {/* <ContextMenu style={{ left: clientRect.x, top: clientRect.y }}/> */}
                    
                
                    <Col md={5}>

                        <div className={'actions'}>

                            <NewAction action={action} setAction={setAction} saveAction={saveAction} currentSelection={currentSelection} setCurrentSelection={setCurrentSelection}/>

                            <h4 className={'h4'}>Autres actions</h4>
                            { actions.map(action => {

                                return (
                                    <Action action={action} />
                                )

                            })}

                        </div>


                        {/*                     
                        <div className={'trigger'}>
                            <div className={'h4'}>Nouveau trigger</div>
                            <div className={'selected-text'}>{currentSelection}</div>
                            <div className={'trigger-buttons'}>
                                <Button onClick={()=>addTrigger()}>+ Ajouter</Button>
                            </div>

                            <div className={'triggers-list'}>
                                { triggers.map(trigger => {
                                    return (
                                        <div className={'trigger'}>
                                            { trigger.text }
                                            <div className={'delete'}>x</div>
                                        </div>
                                    )
                                })}
                            </div>

                            <div className={'action-buttons'}>
                                <Button onClick={()=>addAction()}>+ Ajouter une action</Button>
                            </div>

                        </div> */}

                    </Col>
                </Row>
            </ChannelContext.Provider>
        </>
    )
}

export default Channels
