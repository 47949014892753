import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import { authHeader } from '../../_helpers';
import { Input, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label } from 'reactstrap';

import { Button } from '@mui/material';

import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDateTimePicker from '@mui/lab/MobileDateTimePicker';
// import { AdapterDateFns, LocalizationProvider, MobileDateTimePicker } from '@mui/lab';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faChevronRight, faCheck, faTrash  } from '@fortawesome/free-solid-svg-icons'

import { Delete, Edit, AccessTime, AssignmentTurnedIn } from '@mui/icons-material';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


const Tasks = () => {
    const [ tasks, setTasks ] = useState([])
    const [ editModal, setEditModal ] = useState(false)
    const [ ready, setReady ] = useState(false)
    const [ editingTask, setEditingTask ] = useState(null)
    
    useEffect(()=> {

        getTasks();
        
    }, [])

    const options = {
        selectableRowsHeader:false,
        selectableRowsHideCheckboxes:true,
    };

    const onDelete = (task) => {
        console.log('onDelete', task)
    }

    const toggleEditModal = () => setEditModal(!editModal)

    const openEditModal = (task) => {

        setEditingTask(task)
        toggleEditModal()

    }

    const deleteTask = async (task) => {
       
        fetch(`${process.env.apiUrl}/v1/tasks/${task.id}`, 
        {
            method: 'DELETE',
            headers: await authHeader()
        })
        .then(function(response) {
            return response.json();
        })
        .then(function(json) {       
            
            console.log('Done SaveTask', json)
            tasks.splice( tasks.indexOf(task), 1)
            setTasks([...tasks])
            // setReady(true)

        });
        
    }
    const confirmDeleteTask = (task) => {
        confirmAlert({
          title: 'Confirm to delete',
          message: 'Are you sure to do this.',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                    deleteTask(task)
              }

            },
            {
              label: 'No',
              onClick: () => {

              }
            }
          ]
        });
      };
    
    const columns = [
        // {
        //     name: "enabled",
        //     label: "enabled",
        //     options: {
        //         filter: true,
        //         sort: false,
        //         customBodyRender: (v, row) => {
        //             let channel = data[row.rowIndex]
        //             return (                        
        //                 <Input type={'checkbox'} checked={channel.enabled} onChange={()=>toggleChannel(channel)}/>
        //             )
        //         }
        //     },
        // },
        {
            name: "time",
            label: "Date",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (v, row) => {
                    let task = tasks[row.rowIndex]

                    return (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>

                        <MobileDateTimePicker
                            //timeIcon={AccessTime}
                            value={v}
                            disabled={task.done}
                            onChange={(newValue) => {
                                console.log({newValue})
                                setValue(task, 'time', newValue);
                                saveTask(task)
                            }}
                            renderInput={(params) => <TextField startIcon={AccessTime} {...params} />}
                        />
                    </LocalizationProvider>
                    )
                }
            }
        },
        {
            name: "exec.type",
            label: "Exec",
            options: {
                filter:true,
                sort: true,
                customBodyRender: (v, row) => {
                    let task = tasks[row.rowIndex]
                    return (                        
                        <div>{ task.exec.type } { JSON.stringify(task.exec.options) }</div>
                    )
                }                
            }
        },          
        {
            name: "done",
            label: "Actions",
            options: {
                filter:true,
                sort: true,
                customBodyRender: (v, row) => {
                    let task = tasks[row.rowIndex]

                    if(task.done) return <Button disabled startIcon={<AssignmentTurnedIn/>}>Done</Button>
                    
                    return (                        
                        <>
                            <Button onClick={()=>openEditModal(task)}  startIcon={<Edit/>}>Edit</Button>
                            <Button onClick={()=>confirmDeleteTask(task)} startIcon={<Delete/>}>Delete</Button> {/*Input type={'checkbox'} disabled checked={task.done}/>*/}
                        </>
                    )
                }                
            }
        },           
        // {
        //     name: "label",
        //     label: "Label",
        //     options: {
        //         filter:true,
        //         sort: true,
        //     }
        // },        
        // {
        //     name: "preview",
        //     label: "👁️",
        //     options: {
        //         customBodyRender: (v, row) => {
         
        //             let channel = channels[row.rowIndex]
                    
        //             return (
        //                 <Button 
        //                     onClick={()=>getChats(channel)}
        //                     color={currentChannel===channel.id ? 'secondary':'primary'}
        //                 >👁️</Button>
                        
        //             )
        //         }
        //     },
        // },
    ];


    const getTasks = async () => {
        fetch(`${process.env.apiUrl}/v1/tasks/`, 
        {
            method: 'GET',
            headers: await authHeader()
        })
        .then(function(response) {
            return response.json();
        })
        .then(function(json) {       
            
            setTasks([...json.results])
            setReady(true)

        });
    }

    const setValue = async (task, field, value) => {
        
        debugger
        tasks[ tasks.indexOf(task) ][field] = value
        setTasks([...tasks])

    }

    
    const saveTask = async (task) => {
        fetch(`${process.env.apiUrl}/v1/tasks/${task.id}`, 
        {
            method: 'PUT',
            headers: await authHeader(),
            body: JSON.stringify({task}),
        })
        .then(function(response) {
            return response.json();
        })
        .then(function(json) {       
            
            console.log('Done SaveTask', json)
            // setTasks([...json.results])
            // setReady(true)

        });
    }


    return (
        <>  
            { editingTask && 
                <Modal isOpen={editModal} toggle={toggleEditModal}>
                    <ModalHeader>
                        Edit task
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="edit_message">Message</Label>                            
                            <Input 
                            type="textarea" 
                            id="edit_message" 
                            value={editingTask.exec.options.message} 
                            onChawnge={(target)=>setValue(editingTask, 'message', target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="edit_channel">Channel</Label>                            
                            <Input type="select" id="edit_channel" value={editingTask.exec.options.channel}>
                                <option>@testchannelbrucewhale</option>
                            </Input>
                        </FormGroup>

                        <FormGroup>
                            <Label for="edit_time">Date and time to execute</Label><br/>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>

                                <MobileDateTimePicker
                                    //timeIcon={AccessTime}
                                    id="edit_time"
                                    value={editingTask.time}
                                    onChange={(newValue) => {
                                        console.log({newValue})
                                        setValue(editingTask, 'time', newValue);
                                    }}
                                    renderInput={(params) => <TextField startIcon={AccessTime} {...params} />}
                                />
                            </LocalizationProvider>
                        </FormGroup>

                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={toggleEditModal}>Cancel</Button>
                        <Button onClick={()=>saveTask(editingTask)}>Save</Button>
                    </ModalFooter>
                </Modal>
            }
            <MUIDataTable
                title={"Tasks list"}
                data={tasks}
                columns={columns}
                options={options}
            />
        </>
    )
}

export default Tasks