
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faGithub, faPhp, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup, Image } from '@themesberg/react-bootstrap';

import { Link } from 'react-router-dom';

import Logo from "/img/logo.png"; 
import { Routes } from "../../routes";
//import BgImage from "../../assets/img/pages/sign-in-background.jpg";

import { authenticationService } from '../../_services';


const Signin = (props) => {
  
  const [ loginForm, setLoginForm ]  = useState({ email: '', password: '' })
  const [ submitting, setSubmitting ]  = useState(false)

  // redirect to home if already logged in
  if (authenticationService.currentUserValue) { 
      props.history.push('/');
  }

  const updateLoginField = (field, value) => {    
    loginForm[field] = value
    console.log(loginForm)
    setLoginForm({...loginForm})
  }
    
  const submitLogin = () => {
    
    
    if(loginForm.email !== '' && loginForm.password !== '') {
      
      setSubmitting(true)

      authenticationService.login(loginForm.email, loginForm.password).then(
        (user) => {          
          
          const { from } = props.location.state || {
            from: { pathname: "/" },
          };
          
          props.history.push(from);

        },
        (error) => {

          setSubmitting(false);
          // setStatus(error);
          
        }
      );
     

    }
    

  }


  return (
    <main style={{ height:'100vh', backgroundImage: `url(/img/5838290.jpg)` } }>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          {/* <p className="text-center">
            <Card.Link as={Link} to={Routes.DashboardOverview.path} className="text-gray-700">
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to homepage
            </Card.Link>
          </p> */}
          <Image src={Logo} style={{margin:'25px auto 45px',width:'30%',display:'flex'}}/>
          {/* <h1 className={'login-logo'}>BruceWhale™</h1> */}
          <Row className="justify-content-center form-bg-image">

            <Col xs={12} className="d-flex align-items-center justify-content-center">
              

              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Connectez-vous</h3>
                </div>
                <Form className="mt-4">
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Votre courriel</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="email" placeholder="exemple@moneysmartinvestmentgroup.com" onChange={({target}) => updateLoginField('email', target.value)}/>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Votre mot de passe</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control required type="password" placeholder="Mot de passe"  onChange={({target}) => updateLoginField('password', target.value)} />
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <Form.Check type="checkbox">
                        <FormCheck.Input id="defaultCheck5" className="me-2" />
                        <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Se souvenir de moi</FormCheck.Label>
                      </Form.Check>
                      <Card.Link className="small text-end">
                        <Link to={'/forgot-password'}>Mot de passe oublié?</Link>
                      </Card.Link>
                    </div>
                  </Form.Group>
                  <Button disabled={submitting} onClick={submitLogin} variant="primary" className="w-100">
                    Connexion
                  </Button>
                </Form>
 
                {
                  /*
                <div className="mt-3 mb-4 text-center">
                  <span className="fw-normal">or login with</span>
                </div>
                <div className="d-flex justify-content-center my-4">
                  <Button variant="outline-light" className="btn-icon-only btn-pill text-facebook me-2">
                    <FontAwesomeIcon icon={faFacebookF} />
                  </Button>
                  <Button variant="outline-light" className="btn-icon-only btn-pill text-twitter me-2">
                    <FontAwesomeIcon icon={faTwitter} />
                  </Button>
                  <Button variant="outline-light" className="btn-icon-only btn-pil text-dark">
                    <FontAwesomeIcon icon={faGithub} />
                  </Button>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Not registered?
                    <Card.Link as={Link} to={Routes.Signup.path} className="fw-bold">
                      {` Create account `}
                    </Card.Link>
                  </span>
                </div>
                */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default Signin