import React from 'react';
import { Row, Col, Card, CardHeader } from 'reactstrap';

const Parametres = () => {
    return (
        <>
            <Card border="light" className="shadow-sm">
                <CardHeader>
                <Row className="align-items-center">
                    <Col>
                        <h2>Paramètres</h2>
                    </Col>
                    <Col className="text-end">
                    {/* <Button variant="secondary" size="sm">See all</Button> */}
                    </Col>
                </Row>
                </CardHeader>
            </Card>      
        </>
    )
}

export default Parametres