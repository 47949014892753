import React, { useState, useEffect } from 'react'

import { Paper, Button,  } from '@mui/material';
import SaveMessage from '../Traps/Exec/SaveMessage';
import SendMessage from '../Traps/Exec/SendMessage';
import GetPrice from '../Traps/Exec/GetPrice';

import { 
    Modal, 
    ModalHeader, 
    ModalFooter, 
    ModalBody, 
    Input, 
    FormGroup, 
    ButtonGroup,
    Label } from 'reactstrap';

    
import { authHeader } from '../../_helpers'

const listExecutes = [ 
    {
        id: 'SaveMessage',
        label:"💾 Save the message",
        module:SaveMessage
    }, 
    {
        id: 'SendMessage',
        label:"💬 Send a message",
        module:SendMessage
    },
    {
        id: 'GetPrice',
        label:"📈 Get price",
        module:GetPrice
    }
];

const TrapEdit = ({ trapId, toggleModal, modalOpen, getTraps }) => {

    const [ trap, setTrap ] = useState({})
    const [ ready, setReady ] = useState(false);
    const [ newActionExec, setNewActionExec ] = useState({ type:'SaveMessage', options: {} })
    

    useEffect(() => {
        if(modalOpen) getTrap()

    }, [modalOpen])


    const getTrap = async() => {

        fetch(`${process.env.apiUrl}/v1/traps/${trapId}`, {

            headers: await authHeader(),
            method: 'GET'
        })
        .then(function(response) {
            return response.json();
        })
        .then(function(json) {            
            setTrap({...json.result})
            setReady(true)
        })



    }

    const delTrap = async() => {

        fetch(`${process.env.apiUrl}/v1/traps/${trapId}`, {

            headers: await authHeader(),
            method: 'DELETE'
        })
        .then(function(response) {
            return response.json();
        })
        .then(function(json) {            
            getTraps()
            toggleModal()
        })

    }
    
    const saveTrap = async() => {

        
        fetch(`${process.env.apiUrl}/v1/traps/${trap.id}`, {
            headers: await authHeader(),
            method: 'PUT',
            body: JSON.stringify(trap)
        }) 
        .then(function(response) {
            return response.json();
        })
        .then(function(json) {

            toggleModal()
            getTraps()
        });


    }

    const delTrigger = (tNum) => {

        trap.triggers.splice(tNum, 1)

        setTrap({...trap})
        //saveTrap(trap)
        //setAction({...trap})        

    }

    const addTrigger = () => {
            
        trap.triggers.push({
                text: '',
                condition: 'and'
            })
    
            setTrap({...trap})

            // saveTrap(trap)
            //setAction({...trap})        
    
    }

    const changeTriggerText = (tNum, text) => {

        trap.triggers[tNum].text = text
        setTrap({...trap})

    }
       
    const handleExecChange = (eNum, form) => {
        console.log('handleExecChange', eNum, form);
        trap.executes[eNum].options = form;
        setTrap({ ...trap });
    }

    
    const addExec = () => {
        
        trap.executes.push({
            ...newActionExec
        })

        // action.channel = currentChannel

        setTrap({...trap})        
        // setCurrentSelection('');    
    }    


    if(!ready) return <div></div>

    
    return (
        <>
            <Modal size={'md'} toggle={toggleModal} isOpen={modalOpen}>
                <ModalHeader>
                    Modifier le trap
                </ModalHeader>
                <ModalBody>
    
                    <FormGroup>
                        <Label for="new_trap"/>
                        {/* <Input 
                            id="new_trap"
                            placeholder="Texte à attraper"
                            value={currentSelection}
                            onChange={({target})=>setCurrentSelection(target.value)}
                        /> */}


                    </FormGroup>

                    <div className={'triggers-list'}>
                        { trap.triggers.map((trigger, tNum) => {
                            return (
                                <div className={'trigger'}>
                                    { tNum > 0 && <strong style={{border:'1px solid #ccc',borderRadius:20,padding:'0.5em'}}>{ trigger.condition }</strong>} 
                                        <input value={ trigger.text } onChange={({target})=>changeTriggerText(tNum, target.value)} />
                                    <div className={'delete'} onClick={()=>delTrigger(tNum)}>x</div>
                                </div>
                            )
                        })}
                        
                    </div>

                
                    <div className="new-trigger">
                        { trap.triggers.length > 0 &&
                            <div className={'condition'}>
                                <ButtonGroup>
                                    {/* <Button onClick={()=>{setNewActionTrigger({ ...newActionTrigger, condition:'or'});  } } color={newActionTrigger.condition==='and'?'primary':'secondary'}>and</Button>
                                    <Button onClick={()=>{setNewActionTrigger({ ...newActionTrigger, condition:'and'});  } } color={newActionTrigger.condition==='or'?'primary':'secondary'}>or</Button> */}
                                </ButtonGroup>
                            </div>
                        }
                        {/* <div className={'selected-text'}>{currentSelection}</div>  */}
                    </div>
                

                    <div className={'trigger-buttons'}>
                        <Button onClick={()=>addTrigger()}>+ Ajouter un trigger</Button>
                    </div>

                    <div className={'triggers-list'}>
                        { trap.executes.map((exec, eNum) => {
                        
                            const CurrentExec = listExecutes.find(x => x.id === exec.type).module;
                            console.log('exec dans trigger list', exec)
                            return (
                                <>
                                    <Input
                                        value={exec.type}
                                        onChange={({ target })=> {
                                            
                                            // console.log('executes', eNum, exec, action.executes)
                                            trap.executes[eNum] = { type: target.value }
                                            
                                            //
                                            setAction({...action})   

                                        }} //{({target}) => { console.log('newaction', target.value); setNewActionExec({ type: target.value }) } }
                                        type="select"
                                    >
                                        { listExecutes.map(e => {
                                            return (
                                                <option value={e.id}>{ e.label }</option>
                                            )
                                        })}
                                    </Input>

                                    <CurrentExec eNum={eNum} updateDetails={handleExecChange} exec={exec}/>
                                </>
                            )
                            // return (
                            //     <div className={'trigger'}>
                            //         { currentExec.description }
                            //         <div className={'delete'} onClick={()=>delExec(eNum)}>x</div>
                            //     </div>
                            // )
                        })}
                        <Button color={'secondary'} onClick={()=>addExec()}>+ Ajouter une execution</Button> 
                    </div>

                </ModalBody>
                <ModalFooter>
                    <Button onClick={()=>{delTrap()}}>Supprimer</Button>
                    <Button onClick={()=>{saveTrap()}}>Sauvegarder</Button>
                </ModalFooter>
            </Modal>
        </>

    )

}    

export default TrapEdit
    