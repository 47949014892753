import moment from 'moment';
import { authenticationService } from '../_services';


export const authHeader = async () => {
    // return authorization header with jwt token
    let currentUser = authenticationService.currentUserValue;
    let refreshToken = currentUser.tokens.refresh.token;
    let expiration = currentUser.tokens.access.expires;
    let now = moment();
    
    if (moment(now).isAfter(expiration)) {
        
        currentUser = await accessTokenByRefreshToken(refreshToken);
        
    }
    
    if (currentUser && currentUser.tokens) {
        
        return { Authorization: `Bearer ${currentUser.tokens.access.token}`, 'Accept':'application/json','Content-Type':'application/json' };
    } else {
        
        return {};
    }
}


function accessTokenByRefreshToken(refreshToken) {
    
    return authenticationService.createAccessTokenByRefreshToken(refreshToken);
}