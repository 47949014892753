// import config from 'config';


import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../_helpers';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout, 
    forgotPassword,
    createAccessTokenByRefreshToken,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

function createAccessTokenByRefreshToken (refreshToken) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ refreshToken })
    };
    
    return fetch(`${process.env.apiUrl}/v1/auth/refresh-tokens`, requestOptions)
        .then(handleResponse)
        .then(tokens => {

            let storedUser = JSON.parse(localStorage.getItem('currentUser'));
            storedUser.tokens = tokens
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(storedUser));

            currentUserSubject.next(storedUser);

            return storedUser;
        });  
}

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };
    
    return fetch(`${process.env.apiUrl}/v1/auth/login`, requestOptions)
        .then(handleResponse)
        .then(user => {

            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}

function forgotPassword(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
    };
    
    return fetch(`${process.env.apiUrl}/v1/auth/forgot-password`, requestOptions)
        .then(handleResponse)
        .then(() => {

            return true;

        });
}