
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, Container, InputGroup, Alert } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

//import BgImage from "../../assets/img/pages/sign-in-background.jpg";
import { Routes } from "../../routes";

import { authenticationService } from '../../_services';


const ForgotPassword = () => {
  
  const [ form, setForm ] = useState({ email: '' })
  const [ isSubmitting, setSubmitting ]  = useState(false)
  const [ status, setStatus ] = useState({})
  const [ show, setShow ] = useState(false)
  const [ done, setDone ] = useState(false)

  const submitForgotPassword = (e) => {

    e.preventDefault();

    if(form.email !== '') {
      
      setSubmitting(true)

      authenticationService.forgotPassword(form.email).then(
        () => {   

          //setSubmitting(true);          
          setStatus({variant:'success', title:'Bien joué!', message:'Veuillez vérifier votre courriel'});
          setShow(true)
          setDone(true)
        },
        (error) => {

          setSubmitting(false);          
          setStatus({variant:'danger', title:'Erreur!', message:error});
          setShow(true)
          
        }
      );
     

    }

  }


  return (
    <main>
      <section className="vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center"  style={{ height:'100vh', backgroundImage: `url(/img/5838290.jpg)` } }>
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3>Mot de passe oublié?</h3>
                <p className="mb-4">Pas de problème! Entrez simplement votre courriel ici et nous vous enverrons un lien pour réinitialiser votre mot de passe!</p>
                <Form onSubmit={submitForgotPassword}>
                  <div className="mb-4">
                    <Form.Label htmlFor="email">Votre courriel</Form.Label>
                    <InputGroup id="email">
                      <Form.Control required disabled={done} autoFocus type="email" placeholder="exemple@seqens.com"  onChange={({target}) => setForm({ email: target.value })} />
                    </InputGroup>
                  </div>
                  { (status && show) && 
                    <Alert variant={status.variant} onClose={() => setShow(false)} dismissible>
                      <Alert.Heading>
                        { status.title }
                      </Alert.Heading>
                      <p>
                        { status.message }
                      </p>
                  </Alert>
                  }
                  <Button disabled={isSubmitting || form.email === ''} onClick={submitForgotPassword} variant="primary" className="w-100">
                    { (isSubmitting && !done) && <FontAwesomeIcon icon={faSpinner} className="me-2 fa-spin" /> }
                    Réinitialiser le mot de passe
                  </Button>
                </Form>
                <br/>
                <p className="text-center">
                  <Card.Link as={Link} to={Routes.Signin.path} className="text-gray-700">
                    <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Retour à la connexion
                  </Card.Link>
                </p>
              </div>
              
            </Col>
            
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default ForgotPassword;