import React, { useEffect, useState } from 'react';
import Channel from './Channel'
import MUIDataTable from "mui-datatables";
import { Row, Col, Input, Button } from 'reactstrap';
import ChatMessage from './ChatMessage'


const ChannelsList = () => {

    const [ channels, setChannels ] = useState([])
    const [ chats, setChats ] = useState([])
    const [ ready, setReady ] = useState(false)
    const [ currentChannel, setCurrentChannel ] = useState(null)
        

    const toggleChannel = (channel) => {
        console.log('toggleChannel', channel)
        // let channel = channels[channelIdx]
        channel.enabled = !channel.enabled

        fetch(`${process.env.apiUrl}/v1/chat-updates/update`, 
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ...channel })
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)

            
        });
        
        setChannels({...channels})
    }

    const data = []

    Object.keys(channels).map(channel => {

        data.push(channels[channel])

    })


    const columns = [
        {
            name: "enabled",
            label: "enabled",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (v, row) => {
                    let channel = data[row.rowIndex]
                    return (                        
                        <Input type={'checkbox'} checked={channel.enabled} onChange={()=>toggleChannel(channel)}/>
                    )
                }
            },
        },
        {
            name: "title",
            label: "Title",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "messages",
            label: "Messages",
            options: {
                sort: true,
            }
        },        
        {
            name: "preview",
            label: "👁️",
            options: {
                customBodyRender: (v, row) => {
         
                    let channel = channels[row.rowIndex]
                    
                    return (
                        <Button 
                            onClick={()=>getChats(channel)}
                            color={currentChannel===channel.id ? 'secondary':'primary'}
                        >👁️</Button>
                        
                    )
                }
            },
        },
    ];


    const options = {
        selectableRowsHeader:false,
        selectableRowsHideCheckboxes:true,
    };

    useEffect(()=> {

        getChannels();
        
    }, [])


    const getChannels = () => {

        
        fetch(`${process.env.apiUrl}/v1/chat-updates/channels`)
        .then(function(response) {
            return response.json();
        })
        .then(function(json) {       
            
            setChannels({...json.results})
            setReady(true)

        });
    }

    const getChats = (channel) => {
        
        setCurrentChannel(channel.id)

        fetch(`${process.env.apiUrl}/v1/chat-updates/?channel_id=${channel.id}`)
        .then(function(response) {
            return response.json();
        })
        .then(function(json) {       
            
            setChats([...json.results])
            setReady(true)

        });
    }

    
    if(!ready) return <>loading</>


    console.log({ chats })
    return (
    <Row>
        <Col>
            <MUIDataTable
            title={"Channel List"}
            data={data}
            columns={columns}
            options={options}
            />
        </Col>
        <Col>
            <div className={'chat-scroller'}>
                { chats.map(chat => {
                    return (
                        <ChatMessage chat={chat}/>
                    )
                  })
                }
            </div>
        </Col>
        {/* { Object.keys(channels).map(channel => {
            return <Channel channel={ channels[channel] } />                
        })} */}
    </Row>
    )
}

export default ChannelsList