import React from 'react';

const GetPrice = () => {


    return (
        <div className={'exec-object'}>
            <div className={'exec-description'}>
                Price will be saved
            </div>
        </div>
    )
}


export default GetPrice;