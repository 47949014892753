import React from 'react';
import { format } from "date-fns";

console.log({ format })

const ChatMessage = ({ chat }) => {

    
    let date = format(new Date(chat.date*1000), 'dd/MM/yyyy HH:mm:ss');
    console.log({ chat, date })
    return (
        <>
            <div className={'chat-message'}>

                <div className={'chat-infos'}>
                    { chat.sender &&
                        <div className={'chat-user'}>
                            Sender: { chat.sender }
                        </div>
                    }
                    <div className={'chat-date'}>
                        {  date }
                    </div>
                </div>
                
                <div className={'chat-content'}>
                    { chat.message }
                </div>
                
            </div>
            
        </>
    )

}

export default ChatMessage