import React, { useState } from 'react';
import { 
    Modal, 
    ModalHeader, 
    ModalFooter, 
    ModalBody, 
    Button, 
    Input, 
    FormGroup, 
    Label } from 'reactstrap';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import TextField from '@mui/material/TextField';

import { authHeader } from '../_helpers'

const SendTelegram = () => {
    const [ modalOpen, setModalOpen ] = useState(false)
    const [ msgForm, setMsgForm ] = useState({ channel:'@testchannelbrucewhale', message: '' })

    const toggleModal = () => setModalOpen(!modalOpen)
    
    const sendMessage = async () => {
        fetch(`${process.env.apiUrl}/v1/telegram/send`,
        {
            headers: await authHeader(),
            method: "POST",
            body: JSON.stringify({...msgForm})
        })
        .then(function(res){ return res.json(); })
        .then(function(data){ 
            
            console.log({data})
            //alert( JSON.stringify( data ) ) 
            toggleModal()

        })
        .catch(function(res){ console.log(res) })

    }


    return (
        <div>
            <Button onClick={toggleModal}>Send Custom Telegram Message</Button>
            <Modal size={'lg'} toggle={toggleModal} isOpen={modalOpen}>
                <ModalHeader>
                    Send Custom Telegram Message
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="message">Message:</Label>
                        <Input 
                            value={msgForm.message}
                            onChange={({target})=>setMsgForm({ ...msgForm, message: target.value })} 
                            id="message" 
                            type="textarea"
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="channel">Channel:</Label>
                        <Input 
                            value={msgForm.channel}
                            onChange={({target})=>setMsgForm({ ...msgForm, channel: target.value })}
                            type="select"   
                            id="channel"
                        >
                            <option value="@testchannelbrucewhale">@testchannelbrucewhale</option>
                        </Input>
                    </FormGroup>

                    <FormGroup>
                        <Label for="date-to-send">Date d'envoi:</Label>
                        <br/>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                label=""
                                id="date-to-send"
                                value={msgForm.dateTime}
                                onChange={(newValue)=>setMsgForm({ ...msgForm, dateTime: newValue })} 
                                renderInput={(params) => <TextField {...params} />} 
                            />
                        </LocalizationProvider>

                    </FormGroup>

                    
                    
                </ModalBody>
                <ModalFooter>
                    <Button onClick={sendMessage}>Send</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default SendTelegram