import React from 'react'
import { format } from 'date-fns';

const Task = ({ task }) => {

    return (
        <div className="task">
            
            <div>{ task.done ? (<span>✔️</span>) : (<span>🕒</span>) }</div>
            <div>{ format(new Date(task.time), 'Y-M-d H:i:s') }</div>
            <div>{ task.exec.type }</div>
            <div>{ JSON.stringify(task.exec.options)  }</div>
        </div>
    )
}

export default Task