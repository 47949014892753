import React, { useState, useEffect } from 'react';
import { Label, FormGroup, Input, Card, CardHeader, CardBody, CardFooter, Table, Row, Col, Button } from 'reactstrap';
import { authHeader, history } from '../../_helpers';
import Password from './Password';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faCheck,  } from '@fortawesome/free-solid-svg-icons'

const UserEdit = (props) => {
    
    let { id } = props.match.params
    const [ ready, setReady ] = useState(false)
    const [ userInfos, setUserInfos ] = useState({})

    useEffect(() => {
        getUserInfos()
    }, [])

    const saveUser = async () => {

        let formToSave = {
            name: userInfos.name,
            email: userInfos.email,            
            //...userInfos
        }

        if(userInfos.password !== '') {
            formToSave.password = userInfos.password;
        }

        const requestOptions = { 
            method: 'PATCH', 
            headers: await authHeader(), 
            body: JSON.stringify(formToSave) 
        };
        
        try {
            const response = await fetch(`${process.env.apiUrl}/v1/users/${id}`, requestOptions)
            let json = await response.json()
            console.log('json', json)
            setUserInfos({...json})
            setReady(true)

            history.push('/admin/users/')
        } catch (ex) {
            //if invalid token
            
        }     
    }

    const getUserInfos = async () => { 

        const requestOptions = { method: 'GET', headers: await authHeader() };
        
        try {
            const response = await fetch(`${process.env.apiUrl}/v1/users/${id}`, requestOptions)
            let json = await response.json()
            console.log('json', json)
            setUserInfos({...json})
            setReady(true)
        } catch (ex) {
            //if invalid token
            
        }

    }

    if(!ready) return <div>Loading</div>

    return (
        <Card border="light" className="shadow-sm">
            <CardHeader>
            <Row className="align-items-center">
                <Col>
                    { id === 'new' ? <h2>Ajouter un nouvel utilisateur</h2> : <h2>Modifier l'utilisateur</h2> }
                </Col>
                <Col className="text-end">
                {/* <Button variant="secondary" size="sm">See all</Button> */}
                </Col>
            </Row>
            </CardHeader>
            
            <CardBody>
                <FormGroup>
                    <Label>Name</Label>
                    <Input value={userInfos.name} onChange={({target})=>setUserInfos({...userInfos, name:target.value })} />
                </FormGroup>

                <FormGroup>
                    <Label>Courriel</Label>
                    <Input value={userInfos.email} onChange={({target})=>setUserInfos({...userInfos, email:target.value })} />
                </FormGroup>

                <FormGroup>
                    <Label>Mot de passe</Label>
                    
                    <Password password={''} clickable={id === 'new' ? false: true} onChange={(password)=>setUserInfos({...userInfos, password })}/>
                    {/* <Input value={userInfos.password} onChange={({target})=>setUserInfos({...userInfos, name:target.value })}/> */}
                </FormGroup>
                
                
            </CardBody>
            
            <CardFooter>
                <Button size={'lg'} color={'success'} onClick={saveUser}><FontAwesomeIcon icon={faCheck} />  Sauvegarder</Button>{' '}
                <Button size={'lg'} color={'grey'} onClick={saveUser}><FontAwesomeIcon icon={faChevronLeft} />  Retour à la page précédente</Button>
            </CardFooter>

        </Card>
        
    )
}

export default UserEdit