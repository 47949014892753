import React, { useState } from 'react'

import { Paper, Button,  } from '@mui/material';
import Task from './Task'

import TrapEdit from './TrapEdit'


const Trap = ({ trap, getTraps }) => {

    const [modalOpen, setModalOpen] = useState(false);
    // const [ editTrap, setEditTrap ] = useState(false)
    // const [ modifiedTrap, setModifiedTrap ] = useState(null)

    
    
    const toggleModal = (modTrap) => {
        // setModifiedTrap({...JSON.parse(JSON.stringify(modTrap )) })
        // let newTrap = JSON.parse(JSON.stringify({...modTrap}))
        // let newTrap = Object.assign({}, modTrap)
        // let newTrap = {...modifiedTrap }
        // newTrap.triggers = [...modTrap.triggers]

        setModalOpen(!modalOpen);
    }

    
    console.log({ trap })

    return (
        <>
           
            <TrapEdit trapId={trap.id} toggleModal={toggleModal} modalOpen={modalOpen} getTraps={getTraps} />

            <div className="trap_div" key={trap.id}>
                <Paper className="trap_block">
                    <div className="channel_data">
                        Channel: { trap.channelInfo ? trap.channelInfo.title : '-'}
                    </div>
                    <div className={'filter_data'}>
                        <div className="filter_triggers">
                            Trigger: 
                            { trap.triggers.map((trigger, t) => {
                                return (
                                    <>
                                        { t > 0 && 
                                            <span className="and">
                                                { trigger.condition}
                                            </span> 
                                        }
                                        <span>
                                            { trigger.text }
                                        </span> 
                                    </>
                                )
                            })}
                        </div>
                        <div className="filter_actions">
                            { trap.executes.map(exec => {
                                return (
                                    <div>
                                        { JSON.stringify(exec)}
                                    </div>
                                )
                            })}
                        </div>
                        <Button onClick={()=>toggleModal(trap)}>Modifier</Button>
                    </div>
                    
                    { trap.messages.map(msg => {
                        return (
                            <div className="msg">
                                
                                <div className="date">
                                    { msg.createdAt }
                                </div>
                                <div className="text">
                                    
                                    <div>{ msg.message }</div>
                                </div>
                                
                                { msg.tasks.map(task => {
                                    return <Task task={task} />
                                })}
                                {/* <Button>Ajouter une tâche</Button> */}
                            </div>
                        )
                    })}
                                                    
                </Paper>                                                        
            </div>
        </>
    )

}

export default Trap