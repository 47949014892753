import React, { useState, useEffect, useContext } from 'react';
import { Button, ButtonGroup, Input, FormGroup, Label } from 'reactstrap';
import { ChannelContext } from '../../_context'

import Switch from '@material-ui/core/Switch';
import SaveMessage from './Exec/SaveMessage';
import SendMessage from './Exec/SendMessage';

const listExecutes = [ 
    {
        id: 'SaveMessage',
        label:"💾 Save the message",
        module:SaveMessage
    }, 
    {
        id: 'SendMessage',
        label:"💬 Send a message",
        module:SendMessage
    }
];
const blankAction = { triggers:[], executes: [], channel:null }
const DefaultExecute = 'SaveMessage';

const NewAction = ({ currentSelection, setCurrentSelection, saveAction, action, setAction, currentChannel }) => {

    
    const [ newActionTrigger, setNewActionTrigger ] = useState({ text:'', condition:'and'})
    const [ newActionExec, setNewActionExec ] = useState({ type:'SaveMessage', options: {} })
    
    const [ filter, setFilter ] = useState(false)

    const addTrigger = () => {

        
        action.triggers.push({
            ...newActionTrigger,            
            text: currentSelection,
        })

        action.channel = currentChannel

        setAction({...action})        
        setCurrentSelection('');                    
    }


    const addAction = () => {
        
        // setAction([ ...action.triggers, {
        //     text: currentSelection,
        // }])
    }    
    

    const addExec = () => {
        
        action.executes.push({
            ...newActionExec
        })

        action.channel = currentChannel

        setAction({...action})        
        setCurrentSelection('');    
    }    

    const preSaveAction = (action) => {

        action.channel = currentChannel
        saveAction(action)
        setAction(blankAction)
    }


    const delTrigger = (tNum) => {

        action.triggers.splice(tNum, 1)
        setAction({...action})        

    }

    const delExec = (tNum) => {

        action.executes.splice(tNum, 1)
        setAction({...action})        

    }


    const handleChange = () => {
        //setFilter(!filter)
        action.preview = !action.preview
        setAction({ ...action })
    }

    const handleExecChange = (eNum, form) => {
        console.log('handleExecChange', eNum, form);
        action.executes[eNum].options = form;
        setAction({ ...action });
    }

    // const updateDetails = () => {

    // }


    return (
        <div className={'new-action'}>

            <div className={'h4'}>Nouvelle trappe</div>
            Channel: { currentChannel }
            <FormGroup>
                <Label for="new_trap"/>
                <Input 
                    id="new_trap"
                    placeholder="Texte à attraper"
                    value={currentSelection}
                    onChange={({target})=>setCurrentSelection(target.value)}
                />


            </FormGroup>

            <div className={'triggers-list'}>
                { action.triggers.map((trigger, tNum) => {
                    return (
                        <div className={'trigger'}>
                            { tNum > 0 && <strong style={{border:'1px solid #ccc',borderRadius:20,padding:'0.5em'}}>{ trigger.condition }</strong>} { trigger.text }
                            <div className={'delete'} onClick={()=>delTrigger(tNum)}>x</div>
                        </div>
                    )
                })}
                
            </div>

        
            <div className="new-trigger">
                { action.triggers.length > 0 &&
                    <div className={'condition'}>
                        <ButtonGroup>
                            <Button onClick={()=>{setNewActionTrigger({ ...newActionTrigger, condition:'or'});  } } color={newActionTrigger.condition==='and'?'primary':'secondary'}>and</Button>
                            <Button onClick={()=>{setNewActionTrigger({ ...newActionTrigger, condition:'and'});  } } color={newActionTrigger.condition==='or'?'primary':'secondary'}>or</Button>
                        </ButtonGroup>
                    </div>
                }
                {/* <div className={'selected-text'}>{currentSelection}</div>  */}
            </div>
        

            <div className={'trigger-buttons'}>
                { currentSelection !== '' && <Button onClick={()=>addTrigger()}>+ Ajouter un trigger</Button> }
            </div>

       
            <hr/>
            <div className={'triggers-list'}>
                { action.executes.map((exec, eNum) => {
                   
                    const CurrentExec = listExecutes.find(x => x.id === exec.type).module;
                    
                    return (
                        <>
                            <Input
                                value={exec.type}
                                onChange={({ target })=> {
                                    
                                    // console.log('executes', eNum, exec, action.executes)
                                    action.executes[eNum] = { type: target.value }
                                    
                                    //
                                    setAction({...action})   

                                }} //{({target}) => { console.log('newaction', target.value); setNewActionExec({ type: target.value }) } }
                                type="select"
                            >
                                { listExecutes.map(e => {
                                    return (
                                        <option value={e.id}>{ e.label }</option>
                                    )
                                })}
                            </Input>

                            <CurrentExec eNum={eNum} updateDetails={handleExecChange}/>
                        </>
                    )
                    // return (
                    //     <div className={'trigger'}>
                    //         { currentExec.description }
                    //         <div className={'delete'} onClick={()=>delExec(eNum)}>x</div>
                    //     </div>
                    // )
                })}
                <Button color={'secondary'} onClick={()=>addExec()}>+ Ajouter une execution</Button> 
            </div>


            <hr/>
            <div className={'action-buttons'}>    
                <div className={'buttons-left'}>
                    
                    <div className={'preview-switch'}>
                        {/* <Switch onChange={handleChange} checked={action.preview} /> */}
                        <Switch
                            checked={action.preview}
                            onChange={handleChange}
                            color="primary"
                            name="checkedB"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        <span style={{marginLeft:10}}>Preview</span>
                    </div>
                </div>            
                <div className={'buttons-right'}>
                    <Button disabled={action.triggers.length===0} color={'success'} onClick={()=>preSaveAction(action)}>Sauvegarder</Button>
                </div>
                
            </div>

        </div> 
    )
}

export default NewAction