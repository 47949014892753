import React from 'react';

const SaveMessage = () => {


    return (
        <div className={'exec-object'}>
            <div className={'exec-description'}>
                💾 Message will be saved
            </div>
        </div>
    )
}


export default SaveMessage;