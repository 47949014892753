import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DateTimePicker from '@mui/lab/DateTimePicker';

// import TextField from '@mui/material/TextField';

const listChannels = [
    '@testchannelbrucewhale',
    '@rien',
]

const defaultMsgForm = { 
    message:'%originalmessage%', 
    channel:'@testchannelbrucewhale',
    delais: 0,
}

const SendMessage = ({ updateDetails, eNum, exec }) => {

    console.log('SendMessage exec', exec)

    const [ msgForm, setMsgForm ] = useState(exec ? exec.options : defaultMsgForm)
    
    useEffect(() => {
        updateDetails(eNum, msgForm)
    }, [msgForm])


    return (
        <div className={'exec-object'}>
            <div className={'exec-description'}>
                
            💬 Send a telegram message

            </div>
            
            <FormGroup>
                <Label for="message">Message:</Label>
                <Input 
                    value={msgForm.message}
                    onChange={({target})=>setMsgForm({ ...msgForm, message: target.value })} 
                    id="message" 
                    type="textarea"
                />
            </FormGroup>

            <FormGroup>
                <Label for="channel">Channel:</Label>
                <Input 
                    value={msgForm.channel}
                    onChange={({target})=>setMsgForm({ ...msgForm, channel: target.value })}
                    type="select"   
                    id="channel"
                >
                    { listChannels.map(channel => {
                        return (
                            <option key={channel} value={channel}>{channel}</option>
                        )
                    })}
                    
                    
                </Input>
            </FormGroup>

            <FormGroup>
                <Label for="delais">Délais avant d'exécuter</Label>
                <Input
                    value={msgForm.delais}
                    onChange={({target})=>setMsgForm({ ...msgForm, delais: target.value })}
                    type="select"   
                    id="delais"
                >
                    <option value={0}>immédiatement</option>
                    <option value={5}>5 minutes après</option>
                    <option value={10}>10 minutes après</option>
                    <option value={15}>15 minutes après</option>
                    <option value={30}>30 minutes après</option>
                    <option value={60}>60 minutes après</option>
                    <option value={120}>120 minutes après</option>
                </Input>
            </FormGroup>
        </div>
    )
}


export default SendMessage;